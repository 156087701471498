.navbar {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.navbar-title {
  font-size: 1.8em;
  font-weight: bold;
  color: #1890ff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.navbar-links {
  display: flex;
  align-items: center;
  gap: 20px;
}

.navbar-link {
  color: #333;
  font-size: 1em;
  text-decoration: none;
  transition: color 0.3s ease;
}

.navbar-link:hover,
.navbar-link.active {
  color: #1890ff;
}

.navbar-menu-button {
  display: none;
  background: none;
  border: none;
  color: #1890ff;
  font-size: 1.8em; /* Larger size for better visibility */
  cursor: pointer;
  padding: 10px; /* Padding for easier tapping */
}

.drawer-menu {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.drawer-link {
  margin-bottom: 10px;
  font-size: 1.2em;
  color: #333;
  text-decoration: none;
  transition: color 0.3s ease;
}

.drawer-link:hover,
.drawer-link.active {
  color: #1890ff;
}

@media (max-width: 768px) {
  .navbar-links {
    display: none;
  }

  .navbar-menu-button {
    display: flex; /* Ensure the button is displayed */
    justify-content: center;
    align-items: center;
    color: #333; /* Ensure good contrast with the background */
    padding-right: 30px; /* Add padding for better usability */
  }
}
