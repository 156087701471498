.admin-marketing-page {
  padding: 20px;
  background-color: #f9f9f9; /* Light background for better contrast */
  min-height: 100vh; /* Ensure full height for the page */
  text-align: center;
}

.overview-card, .marketing-card {
  margin-bottom: 16px; /* Space between cards */
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: box-shadow 0.3s ease; /* Smooth transition for hover effects */
}

.overview-card:hover, .marketing-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

/* Responsive grid adjustments */
@media (max-width: 768px) {
  .overview-card .ant-row, .marketing-card .ant-row {
    flex-direction: column; /* Stack statistics and icons vertically on smaller screens */
  }

  .overview-card .ant-col, .marketing-card .ant-col {
    width: 100%; /* Full width for each statistic or icon */
    margin-bottom: 12px; /* Spacing between items */
  }

  .overview-card .ant-statistic-title, .marketing-card p {
    font-size: 16px; /* Slightly smaller font size for mobile */
  }

  .overview-card .ant-statistic-content, .marketing-card .anticon {
    font-size: 24px; /* Larger icons for easy interaction */
  }
}
