.booking-form-container:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 24px rgba(0, 0, 0, 0.15);
}

.booking-form-title {
    color: #ffcc00;
    text-align: center;
    /* margin-bottom: 24px; */
    font-size: 24px;
    font-weight: bold;
}

.booking-form-tabs .ant-tabs-nav {
    /* margin-bottom: 16px; */
}

.booking-form-tabs .ant-tabs-tab {
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
}

.booking-form-tabs .ant-tabs-tab:hover {
    color: #ffffff;
}

.booking-form-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #ffcc00;
}

.booking-form-tabs .ant-tabs-ink-bar {
    background: #ffcc00;
}

.trip-type {
    display: flex;
    justify-content: space-between;
    /* margin-bottom: 24px; */
}

.trip-type .ant-radio-button-wrapper {
    flex: 1;
    text-align: center;
    /* padding: 12px 0; */
    font-size: 16px;
    /* margin: 0 4px; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.trip-type .ant-radio-button-wrapper-checked {
    background-color: #ffcc00;
    color: #000;
    /* border-radius: 8px; */
}

.booking-form {
    background: #333;
    padding: 8px; /* Increased padding for better touch interaction on mobile */
    border-radius: 16px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    /* margin: 20px auto; Center the form and add vertical spacing */
    transition: transform 0.3s, box-shadow 0.3s;

}

.booking-form:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 24px rgba(0, 0, 0, 0.15);
}

.booking-form-label {
    color: #ffffff;
    font-size: 14px;
    /* margin-bottom: 4px; Reduced margin */
    /* padding: 6px; */
}

.booking-form-input {
    background-color: #f7f7f7;
    color: #000;
    margin-bottom: 8px; 
    /* transition: border-color 0.3s, box-shadow 0.3s; */
}

.booking-form-input .ant-input,
.booking-form-input .ant-picker-input > input {
    background-color: #f7f7f7;
    color: #000;
}

.booking-form-input .ant-input:focus,
.booking-form-input .ant-input:hover,
.booking-form-input .ant-picker-input > input:focus,
.booking-form-input .ant-picker-input > input:hover {
    border-color: #ffcc00;
    /* box-shadow: 0 0 0 2px rgba(255, 204, 0, 0.2); */
}

.booking-form-button {
    background-color: #ffcc00;
    border: none;
    color: #000;
    font-weight: bold;
    padding: 12px 16px;
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 8px;
}

.booking-form-button:hover,
.booking-form-button:focus {
    background-color: #e6b800;
    color: #000;
}

/* Responsive styles */
@media (max-width: 768px) {
    .booking-form {
        /* padding: 12px; /* Reduce padding on smaller screens */
        /* margin: 10px; Reduce margin on smaller screens */ 
    }

    .booking-form-label {
        font-size: 12px; /* Reduce font size on smaller screens */
    }

    .booking-form-button {
        /* padding: 10px 14px; Adjust button padding on smaller screens */
        /* font-size: 14px; Reduce button font size on smaller screens */
    }
}

@media (max-width: 480px) {
    .booking-form {
        /* padding: 8px; Further reduce padding on very small screens */
        margin: 5px; /* Further reduce margin on very small screens */
    }

    .booking-form-label {
        font-size: 10px; /* Further reduce font size on very small screens */
    }

    .booking-form-button {
        /* padding: 8px 12px; Further adjust button padding on very small screens */
        font-size: 12px; /* Further reduce button font size on very small screens */
    }
}

.itinerary-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .stop-tag-wrapper {
    position: relative;
    margin: 8px;
  }
  
  .stop-tag {
    background-color: #ffcc00;
    color: #000;
    border: none;
    font-size: 14px;
    padding: 8px 12px;
    border-radius: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px; /* Increase max-width for better readability */
  }
  
  .stop-tag:hover {
    overflow: visible;
    white-space: normal;
    max-width: none;
    z-index: 1;
    background-color: #ffcc00; /* Ensure background color remains the same */
    padding: 8px 12px; /* Maintain padding on hover */
  }
  
  .arrow-icon {
    margin-left: 8px;
    margin-right: 8px;
  }
  

.stop-tag .ant-tag-close-icon {
    color: #000;
}

.add-stop-button {
    margin-top: 8px;
    background-color: #1890ff;
    color: #fff;
    border: none;
    border-radius: 8px;
    transition: background-color 0.3s;
}

.add-stop-button:hover {
    background-color: #40a9ff;
}

.arrow-icon {
    margin: 0 8px;
    color: #ffcc00;
    font-size: 16px;
}

  .round-trip-checkbox {
    color: #eeeeee;
    margin-bottom: 10px;
  }

#add-more-city-button {
    background-color: #ffcc00;
    border: none;
    color: #000;
    font-weight: bold;
    padding: 12px 16px;
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 8px;
  }
  
 #add-more-city-button:hover {
    background-color: #40a9ff;
    color: white;
  }
  