.demo-container {
    padding: 40px;
    background-color: #f0f2f5;
    min-height: 100vh;
  }
  
  .demo-header {
    text-align: center;
    margin-bottom: 50px;
  }
  
  .demo-title {
    font-size: 2.5rem;
    color: #1890ff;
    margin-bottom: 20px;
  }
  
  .demo-subtitle {
    font-size: 1.25rem;
    color: #666;
    max-width: 760px;
    margin: auto;
  }
  
  .feature-section {
    margin-bottom: 40px;
  }
  
  .feature-card {
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    border-radius: 12px;
    overflow: hidden;
    background: linear-gradient(to bottom right, #ffffff, #f3f3f3);
  }
  
  .feature-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  }
  
  .call-to-action {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  
  .demo-cta-button {
    font-size: 1.2rem;
    padding: 10px 50px;
    background-color: #007aff; /* Blue */
    border-color: #007aff;
    box-shadow: 0 4px 12px rgba(0, 120, 255, 0.3);
  }
  
  .demo-cta-button:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }
  