.about-us-container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 40px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.about-us-title {
  text-align: center;
  color: #1890ff;
}

.about-us-card {
  margin-bottom: 20px;
}

.about-us-subtitle {
  text-align: center;
  margin-top: 40px;
}

.team-member-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.team-member-image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .about-us-container {
    padding: 20px;
  }
}
