.create-template-page {
    padding: 24px;
    background-color: #f0f2f5; /* Light grey background */
  }
  
  .page-title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .option-card {
    text-align: center;
    padding: 20px;
  }
  
  .icon-large {
    font-size: 48px;
    color: #1890ff; /* Ant Design blue */
  }
  
  .option-card p {
    margin: 12px 0;
  }
  