.logo {
    height: 32px;
    /* background: rgba(255, 255, 255, 0.3); */
    margin: 16px;
  }
  
  .site-layout .site-layout-background {
    background: #001529;
    color: #fff;
  }
  