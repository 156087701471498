.bill-container {
  max-width: 800px;
  margin: auto;
  padding: 16px;
}

.company-logo {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.bill-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFD700;
  color: #FFFFFF;
}

.action-button {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.bill-row:hover .action-button,
.main-row:hover .action-button {
  visibility: visible;
  opacity: 1;
}

.add-item-button {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.total-row {
  font-weight: bold;
  background-color: #f0f0f0;
}

.bill-row {
  transition: background-color 0.3s ease;
}

.bill-row:hover {
  background-color: #f5f5f5;
}

.main-row {
  transition: background-color 0.3s ease;
}

.main-row:hover {
  background-color: #e0e0e0;
}

.edit-button {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.editable-fields:hover .edit-button,
.bill-container:hover .edit-button {
  visibility: visible;
  opacity: 1;
}
