.contact-page {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding-top: 80px; /* Account for fixed navbar */
    background-color: #f7f8fa; /* Softer background color */
    min-height: 100vh;
}

.contact-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contact-form-card, .contact-info-card {
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #ffffff;
    transition: box-shadow 0.3s ease;
}

.contact-form-card:hover, .contact-info-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.contact-title {
    color: #1890ff; /* Ant Design primary color */
    margin-bottom: 24px;
}
