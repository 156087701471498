.campaign-dashboard .ant-card {
    margin: 16px;
  }
  
  .campaign-dashboard .ant-btn {
    margin-left: 8px;
  }
  
  .campaign-dashboard .ant-table-thead > tr > th {
    background-color: #f0f2f5;
    font-weight: 500;
  }
  