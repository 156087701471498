.home-layout {
    min-height: 100vh;
    background-image: url('https://example.com/background.jpg'); /* Replace with your background image URL */
    background-size: cover;
    background-position: center;
  }
  
  .home-header {
    background: rgba(0, 0, 0, 0.6);
    padding: 60px 20px;
    color: #fff;
  }
  
  .header-content {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .header-title {
    font-size: 48px;
    margin-bottom: 16px;
  }
  
  .header-description {
    font-size: 18px;
    margin-bottom: 32px;
  }
  
  .booking-form-container {
    background: #000;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #ffcc00;
    max-width: 400px;
    margin: 0 auto;
    margin-left: 16px; /* Add margin to separate from left edge */
  }
  
  .booking-form {
    background: #000;
  }
  
  .product-info {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .home-content {
    padding: 50px 20px;
    text-align: center;
    background: #f5f5f5;
  }
  

  
  .benefit {
    background: #fff;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .home-footer {
    background: #001529;
    color: #fff;
    padding: 50px 20px;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .home-footer {
    background-color: #001529;
    color: #ffffff;
    padding: 40px 20px;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-content a {
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-content a:hover {
    color: #1890ff;
  }
  
  
  .footer-bottom {
    margin-top: 20px;
  }
  
  .footer-bottom .ant-typography {
    color: #ffffff;
  }
  
  .footer-bottom .ant-typography {
    color: #ffffff;
  }
  
  /* Custom style for the contact section */
  .footer-content {
    color: #e4dd11; /* Ensure all text is white */
  }

  .footer-content .ant-typography-title {
    color: #ffcc00; /* Make the title yellow */
  }
  
  .footer-content .ant-typography,
  .footer-content .ant-typography a {
    color: #ffffff;
  }
  
  .footer-content .footer-title {
    color: #ffcc00;
  }

  .title {
    color: #ffcc00;
  }