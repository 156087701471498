.settings-page {
    padding: 20px;
    max-width: 600px;
    margin: auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .settings-page h1 {
    text-align: center;
    margin-bottom: 24px;
  }
  