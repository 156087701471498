/* Admin page styling */
.admin-page {
  padding: 20px;
  background-color: #f0f2f5;
}

.admin-header {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

.card-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.actions-column {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.vehicle-card,
.driver-card {
  margin-bottom: 20px;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.driver-card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

/* Adjustments for button styling to ensure text fits */
.ant-btn {
  padding: 6px 12px;  /* Adjust padding to ensure it looks good */
  font-size: 14px;     /* Suitable font size that fits within buttons */
  border-radius: 4px;  /* Rounded corners for a modern look */
  box-sizing: border-box;  /* Ensures padding and border are included in the width and height */
  display: inline-flex;  /* Use flexbox to center text and icons effectively */
  align-items: center;  /* Center items vertically */
  justify-content: center;  /* Center items horizontally */
  min-width: 90px;  /* Minimum width to prevent text overflow */
  white-space: nowrap;  /* Prevents text from wrapping to the next line */
  overflow: hidden;  /* Hide overflow text */
  text-overflow: ellipsis;  /* Add ellipsis if text is too long */
}

.ant-btn-primary {
  background-color: #1890ff;
  border-color: #1890ff;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: #40a9ff;
  border-color: #40a9ff;
}

.ant-btn-danger {
  background-color: #ff4d4f;
  border-color: #ff4d4f;
}

.ant-btn-danger:hover,
.ant-btn-danger:focus {
  background-color: #ff7875;
  border-color: #ff7875;
}


.driver-card .actions-column {
  margin-top: 12px;
}

.ant-btn {
  border-radius: 6px;
  font-size: 14px;
  padding: 6px 12px;
  transition: background-color 0.3s, border-color 0.3s;
}

.ant-btn-primary {
  background-color: #106ba3;
  border-color: #106ba3;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: #005f8f;
  border-color: #005f8f;
}

.ant-btn-danger {
  background-color: #f5222d;
  border-color: #f5222d;
}

.ant-btn-danger:hover,
.ant-btn-danger:focus {
  background-color: #cf1322;
  border-color: #cf1322;
}

@media (min-width: 768px) {
  .actions-column {
    flex-direction: row;
    gap: 16px;
  }
}

@media (max-width: 768px) {
  .driver-card {
    flex-direction: column;
    align-items: stretch;
  }

  .driver-card > div {
    margin-bottom: 8px;
  }

  .driver-card p {
    margin: 0;
    font-size: 14px;
  }

  .actions-column {
    flex-direction: row;
    gap: 8px;
  }

  .admin-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .admin-header .ant-space {
    width: 100%;
    justify-content: flex-start;
    gap: 8px;
    flex-wrap: wrap;
  }

  .admin-header .ant-input-search {
    flex-grow: 1;
    max-width: 100%;
  }

  .admin-header .ant-btn {
    flex-grow: 1;
    max-width: 48%;
  }
}


