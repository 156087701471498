.messaging-page {
    height: 100vh; /* Full viewport height */
    background-color: #f4f5f7; /* Light gray background for contrast */
  }
  
  .messaging-sider {
    background: #001529; /* Dark blue for sider */
    overflow-y: auto;
    border-right: 1px solid #e8e8e8;
  }
  
  .customer-item {
    cursor: pointer;
    padding: 12px;
    transition: background-color 0.3s;
  }
  
  .customer-item:hover {
    background-color: #1890ff;
  }
  
  .chat-content {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #fff;
  }
  
  .chat-header {
    background-color: #fff;
    padding: 16px;
    border-bottom: 1px solid #e8e8e8;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .customer-details {
    background-color: #fafafa;
    padding: 8px;
    border-radius: 8px;
    color: #333;
    margin-top: 10px;
  }
  
  .chat-messages {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .message {
    margin-bottom: 16px;
    max-width: 70%;
    padding: 12px;
    border-radius: 12px;
    position: relative;
    line-height: 1.5;
  }
  
  .message.customer {
    background-color: #e6f7ff;
    margin-left: auto;
    color: #333;
  }
  
  .message.agent {
    background-color: #d9f7be;
    color: #333;
  }
  
  .message p {
    margin: 0;
  }
  
  .message span {
    font-size: 12px;
    color: #888;
    position: absolute;
    bottom: -16px;
    right: 10px;
  }
  
  .chat-input {
    display: flex;
    align-items: center;
    padding: 16px;
    background-color: #fff;
    border-top: 1px solid #e8e8e8;
    flex-shrink: 0;
    position: sticky; /* Keeps input fixed */
    bottom: 0; /* Ensures input sticks to bottom */
    height: 15%; /* Occupies more space for a larger input area */
  }
  
  .chat-input .ant-input {
    flex: 1;
    margin-right: 8px;
    border-radius: 8px;
    resize: none;
  }
  
  .messaging-sider {
    background: #001529; /* Dark blue for the sidebar */
    overflow-y: auto;
    border-right: 1px solid #e8e8e8;
    height: 100vh; /* Full viewport height */
    width: 280px; /* Fixed width for the sidebar */
    padding-top: 16px;
    position: fixed;
    top: 0;
    left: 0;
  }
  
  .messaging-sider .ant-list-item {
    padding: 12px 20px;
    border-bottom: 1px solid #e8e8e8; /* Separator between items */
    transition: background-color 0.3s, transform 0.2s;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .messaging-sider .customer-item:hover {
    background-color: #1890ff; /* Hover color */
    transform: translateX(5px); /* Slide effect on hover */
  }
  
  .messaging-sider .customer-item.active {
    background-color: #40a9ff; /* Highlight active customer */
    color: #fff;
  }
  
  .messaging-sider .ant-list-item-meta-title {
    color: #fff;
    font-weight: bold; /* Bold font for better visibility */
  }
  
  .messaging-sider .ant-avatar {
    background-color: #87d068; /* Green avatar background */
    margin-right: 12px;
  }
  
  .messaging-sider .ant-list-item-meta-description {
    color: #d3d3d3;
    font-size: 12px;
  }
  
  .customer-list-title {
    color: #fff;
    text-align: center;
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 600;
  }
  
  