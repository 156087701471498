.login-container {
    max-width: 360px;
    margin: 2rem auto;
    padding: 2rem;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 8px;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .login-title {
    text-align: center;
    margin-bottom: 24px;
  }
  
  .login-type-selector {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }
  
  .login-type-selector .ant-radio-button-wrapper {
    width: auto;
    padding: 0 15px;
  }
  
  .input-field, .ant-input-password {
    height: 40px;
    border-radius: 5px;
  }
  
  .login-button {
    height: 40px;
    font-size: 16px;
    background-color: #1890ff;
    border-color: #1890ff;
    border-radius: 5px;
  }
  
  .register-link {
    text-align: center;
    margin-top: 16px;
  }
  