/* Section.css */
.section {
    padding: 40px 20px;
    background-color: #f9f9f9;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .section-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    color: #1890ff;
  }
  