.header-layout {
    background: rgba(255, 255, 255, 0.6);
    padding: 60px 20px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .header-content {
    max-width: 1200px;
    width: 100%;
  }
  
  .header-title {
    font-size: 48px;
    margin-bottom: 16px;
    color: #ffcc00; /* Improve color contrast */
  }
  
  .header-description {
    font-size: 18px;
    margin-bottom: 32px;
  }
  
  .form-col {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .info-col {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
  }
  
  .booking-form-container {
    background: #000;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #ffcc00;
    max-width: 400px;
  }
  
  .booking-form {
    background: #000;
  }
  