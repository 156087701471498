.payment-success-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f0f2f5;
    padding: 20px;
  }
  
  .payment-success-card {
    max-width: 600px;
    width: 100%;
    text-align: center;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .success-icon {
    font-size: 48px;
    color: #52c41a;
    margin-bottom: 20px;
  }
  
  .success-title {
    margin-bottom: 20px;
  }
  
  .success-message {
    font-size: 16px;
    margin-bottom: 30px;
  }
  
  .transaction-details {
    text-align: left;
    margin-bottom: 30px;
  }
  
  .back-button {
    width: 100%;
  }
  