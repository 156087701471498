.landing-page {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f9f9f9;
    color: #333;
  }

    .landing-header {
    background: linear-gradient(135deg, #ffffff, #e6f7ff);
    color: #333;
    text-align: center;
    padding: 80px 20px;
    position: relative;
    /* overflow: hidden; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  }
  
.landing-header {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers content horizontally */
  justify-content: center; /* Centers content vertically */
  text-align: center;
  position: relative;

  background: linear-gradient(135deg, #ffffff, #e6f7ff);
  padding: 60px 20px; /* Adjust padding to ensure text fits well */
  min-height: 300px; /* Ensures the header has sufficient height */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header-content {
  max-width: 900px; /* Ensures the text does not stretch too wide on larger screens */
}

  
  .header-content {
    max-width: 900px;
    margin: auto;
    position: relative;
    z-index: 1;
  }
  
  .header-title {
    font-size: 3.5em;
    font-weight: 800;
    margin-bottom: 20px;
    color: #1890ff;
  }
  
  .header-subtitle {
    font-size: 1.5em;
    margin-bottom: 30px;
    opacity: 0.8;
  }
  
  .cta-buttons {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .cta-button,
  .learn-more-button {
    padding: 10px 20px;
    font-size: 1em;
    border-radius: 25px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
  }
  
  .cta-button {
    background-color: #ff7a45;
    border: none;
    color: white;
  }
  
  .cta-button:hover {
    background-color: #ffa940;
  }
  
  .learn-more-button {
    background-color: transparent;
    border: 2px solid #1890ff;
    color: #1890ff;
  }
  
  .learn-more-button:hover {
    background-color: #1890ff;
    color: #fff;
  }

  .features-section {
    align-items: center;
    justify-content: center;

  }
  
  .features-title {
    font-size: 2.5em;
    color: #1890ff;
    margin-bottom: 20px;
    align-items: center;
    flex: auto;
    justify-content: center;

  }
  
  .features-slider {
    overflow: hidden;
    position: relative;
    max-width: 100%;
    margin: auto;
  }
  
  .features-track {
    display: flex;
    width: calc(250px * 10); /* Adjust based on the number of cards to create continuous loop */
    animation: scrollFeatures 30s linear infinite;
  }
  
  .feature-card {
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: #ffffff;
    color: #333;
    margin: 12px;
    padding: 30px;
    position: relative;
    overflow: hidden;
    text-align: center;
    flex: 0 0 250px; /* Width of each card */
    white-space: normal; /* Allow text to wrap */
  }
  
  .feature-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .feature-icon {
    font-size: 40px;
    color: #1890ff;
    margin-bottom: 15px;
  }
  
  .feature-title {
    font-size: 1.4em;
    margin-bottom: 8px;
    color: #333;
    font-weight: 600;
  }
  
  .feature-description {
    font-size: 0.9em;
    opacity: 0.8;
    color: #666;
  }
  
  @keyframes scrollFeatures {
    0% {
      transform: translateX(0);
    }
    100% {
      /* transform: translateX(calc(-250px * 5)); Adjust this for the total width to loop back */
    }
  }
  
  .testimonials-section {
    padding: 60px 20px;
    text-align: center;
    background-color: #f7f7f7;
  }
  
  .testimonial-cards {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .testimonial-card {
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: #ffffff;
    color: #333;
    margin: 12px;
    padding: 30px;
    position: relative;
    overflow: hidden;
    text-align: center;
    width: 100%;
    max-width: 300px;
  }
  
  .testimonial-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .footer {
    background-color: #f7f7f7;
    color: #333;
    padding: 20px;
    text-align: center;
    position: relative;
    border-top: 1px solid #e0e0e0;
  }
  
  .footer a {
    color: #1890ff;
    transition: color 0.3s ease;
  }
  
  .footer a:hover {
    color: #40a9ff;
  }
  