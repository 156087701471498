.pricing-page {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f9f9f9;
    color: #333;
  }
  
  .pricing-header {
    background: linear-gradient(135deg, #ffffff, #e6f7ff);
    color: #333;
    text-align: center;
    padding: 60px 20px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .header-title {
    font-size: 2.5em;
    font-weight: 700;
    margin-bottom: 20px;
    color: #1890ff;
  }
  
  .header-subtitle {
    font-size: 1.2em;
    margin-bottom: 20px;
    opacity: 0.8;
  }
  
  .pricing-section {
    padding: 40px 20px;
    text-align: center;
    background-color: #ffffff;
  }
  
  .pricing-cards {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .pricing-card {
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    color: #333;
    padding: 30px;
    text-align: center;
    max-width: 300px;
    flex: 0 0 300px;
    margin: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .pricing-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .pricing-price {
    font-size: 2em;
    color: #ff7a45;
    margin-bottom: 20px;
  }
  
  .pricing-features {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
  }
  
  .pricing-features li {
    margin: 8px 0;
    font-size: 1em;
    color: #666;
  }
  
  .pricing-button {
    background-color: #1890ff;
    border: none;
    color: white;
    font-size: 1em;
    padding: 10px 20px;
    border-radius: 25px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
  }
  
  .pricing-button:hover {
    background-color: #40a9ff;
  }
  
  .comparison-section {
    padding: 40px 20px;
    background-color: #f7f7f7;
    text-align: center;
  }
  
  .comparison-title {
    font-size: 2em;
    color: #1890ff;
    margin-bottom: 20px;
  }
  
  .comparison-table {
    max-width: 900px;
    margin: 0 auto;
    background-color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
  }
  
  .ant-table {
    border-radius: 12px;
    overflow: hidden;
  }
  
  .ant-table-thead > tr > th {
    background-color: #e6f7ff;
    font-weight: 600;
    color: #333;
    text-align: center;
  }
  
  .ant-table-tbody > tr > td {
    text-align: center;
    color: #666;
  }
  
  .ant-table-tbody > tr:hover > td {
    background-color: #fafafa;
  }
  
  .ant-table-container {
    border-radius: 12px;
    overflow: hidden;
  }
  
  .ant-table-bordered .ant-table-container {
    border-radius: 12px;
  }
  
  @media (max-width: 768px) {
    .pricing-cards {
      flex-direction: column;
      align-items: center;
    }
  
    .pricing-card {
      max-width: 100%;
      margin: 10px 0;
    }
  
    .header-title {
      font-size: 2em;
    }
  
    .header-subtitle {
      font-size: 1em;
    }
  
    .pricing-price {
      font-size: 1.5em;
    }
  }
  

  /* Adjustments for the features section to be more mobile-friendly */
.features-section {
  padding: 40px 20px; /* Consistent padding */
  background-color: #ffffff; /* Clean background for the features */
}

.features-title {
  text-align: center; /* Center the title text */
  font-size: 24px; /* Appropriate font size for readability */
  color: #1890ff; /* Styling the color to match your theme */
  margin-bottom: 20px; /* Space between the title and feature cards */
}

.feature-card {
  margin: 10px auto; /* Margin for spacing between cards */
  padding: 20px; /* Padding inside each feature card */
  max-width: 90%; /* Max width to maintain layout */
  box-shadow: 0 2px 10px rgba(0,0,0,0.1); /* Subtle shadows for depth */
  border-radius: 8px; /* Rounded corners for a smoother look */
  display: block; /* Stack cards vertically */
}

.feature-icon {
  font-size: 30px; /* Icon size to be visible */
  color: #1890ff; /* Icon color */
  margin-bottom: 10px; /* Space below icons */
}

.feature-title {
  font-size: 18px; /* Font size for titles */
  color: #333; /* Text color for titles */
  margin-bottom: 5px; /* Margin below the title */
}

.feature-description {
  font-size: 14px; /* Smaller font size for descriptions */
  color: #666; /* Lighter text color for descriptions */
}

/* Ensure this section is scrollable on mobile */
.features-slider {
  overflow-y: auto; /* Allows vertical scrolling if needed */
  padding-bottom: 20px; /* Extra padding at the bottom */
}
