/* Styles for the main layout */
.admin-bookings-page {
  padding: 20px;
  background-color: #f0f2f5;
}

.admin-bookings-header {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-bookings-content {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
}

.card-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.actions-column {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.floating-action-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.booking-card {
  margin-bottom: 20px;
}

/* Compact and stylish metrics cards */
.metrics-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 8px;
  text-align: center;
  flex: 1; /* Allow the card to grow to fill the container */
}

.metrics-title {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  margin-bottom: 4px;
}

.metrics-value {
  font-size: 20px;
  color: #106ba3;
  font-weight: bold;
}

/* Responsive styles for the search bar */
.search-bar {
  width: 100%;
  max-width: 300px;
  margin-bottom: 16px;
  padding: 8px;
  border-radius: 20px;
  border: 1px solid #ddd;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s;
}

.search-bar:focus {
  border-color: #106ba3;
  outline: none;
}

/* Compact layout adjustments */
.metrics-container {
  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
  overflow-x: auto; /* Allow horizontal scrolling on smaller screens */
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  .actions-column {
    flex-direction: row;
    gap: 16px;
  }
}

/* Logo styles */
.logo {
  height: 60px; /* Fixed height for consistency */
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255); /* Ensure the logo text is visible */
  font-size: 20px; /* Larger font size for better visibility */
  font-weight: bold; /* Make the logo text bold */
  padding: 10px 20px; /* Padding to ensure the text does not touch the edges */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.logo:hover {
  background-color: #106ba3; /* Slightly darker on hover for interactive feedback */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .logo {
    justify-content: start; /* Align to the start on smaller screens */
    font-size: 18px; /* Slightly smaller font size on smaller screens */
  }

  .site-layout .ant-layout-sider {
    display: none; /* Hide the Sider on mobile devices */
  }

  .floating-action-button {
    bottom: 70px; /* Adjust position for mobile */
    right: 15px; /* Adjust position for mobile */
  }

  .card-container {
    gap: 12px; /* Reduce gap for mobile */
  }

  .metrics-card {
    padding: 8px; /* Reduce padding for mobile */
    font-size: 12px; /* Smaller text for mobile */
  }

  .metrics-value {
    font-size: 16px; /* Smaller value text for mobile */
  }

  .search-bar {
    max-width: 100%;
  }
}
