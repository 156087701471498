/* CustomerReviews.css */
.customer-reviews {
    margin-top: 20px;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .review-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .review-card {
    width: 100%;
    max-width: 400px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .review-photo {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 20px auto;
  }
  
  .review-comment {
    font-size: 16px;
    margin: 10px 0;
  }
  
  .review-name {
    font-size: 14px;
    color: #888;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
  
  .slick-dots {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  
  .slick-dots li {
    margin: 0 4px;
  }
  
  .customer-reviews .customer-reviews-title {
    padding: 50px 20px;
    text-align: center;
  }