.admin-bookings-page {
    padding: 20px;
    background-color: #f0f2f5;
  }
  
  .admin-bookings-header {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .admin-bookings-content {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
  }
  
  .card-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .actions-column {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .floating-action-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .booking-card {
    margin-bottom: 20px;
  }
  
  @media (min-width: 768px) {
    .actions-column {
      flex-direction: row;
      gap: 16px;
    }
  }
  