.footer {
    background-color: #242424;
    color: #ffffff;
    padding: 40px 60px;
    font-family: 'Segoe UI', Arial, sans-serif;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-wrap: wrap;
    border-bottom: 1px solid #444;
    padding-bottom: 20px;
  }
  
  .footer-block {
    flex: 1;
    min-width: 180px;
    margin-right: 20px;
  }
  
  .footer-block h4 {
    color: #ff9f00; 
    margin-bottom: 15px;
  }
  
  .footer-block ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-block ul li a {
    color: #ccc;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-block ul li a:hover {
    color: #ff9f00;
  }
  
  .social-links a {
    color: #ccc;
    margin-right: 10px;
    font-size: 18px;
    transition: color 0.3s ease;
  }
  
  .social-links a:hover {
    color: #ff9f00;
  }
  
  .footer-bottom {
    text-align: center;
    padding-top: 20px;
  }
  
  .footer-bottom p {
    margin: 0;
    color: #666;
  }
  