.create-campaign-page {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .steps-content {
    margin-top: 24px;
    padding: 24px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .steps-action {
    text-align: right;
    margin-top: 24px;
  }
  
  .ant-list-item-meta-description {
    white-space: pre-line;
  }
  
  .ant-list-item {
    display: flex;
    align-items: center;
  }
  